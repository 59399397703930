import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A simple Electron app that shows a Twitch chat panel for a specified user.  `}</p>
    <pre><code parentName="pre" {...{}}>{`Usage:
npx @programmer5000/twitch-chat-panel <nameOfTwitchUser>
`}</code></pre>
    <p>{`Source code is `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/twitch-chat-panel"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      